import { AspectRatio } from 'components/commons/aspect-ratio';
import IconBox from 'components/commons/icon-box';
import NextImage from 'next/image';
import Link from 'next/link';
import * as React from 'react';
import AliceCarousel from 'react-alice-carousel';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { CSS } from 'styles';
import color from 'styles/color';

import { CarouselContainer, ImageCarousel } from './style';

type Props = {
  imageList?: { alt: string; src: string; link?: string | null }[];
  isLoading: boolean;
  className?: string;
  css?: CSS;
};

function LazyLoader(props) {
  const { src = '', placeholderImg, errorImg, alt, link } = props;
  const [imgSrc, setSrc] = React.useState(placeholderImg || src);

  const onLoad = React.useCallback(() => {
    setSrc(src);
  }, [src]);

  const onError = React.useCallback(() => {
    setSrc(errorImg || placeholderImg);
  }, [errorImg, placeholderImg]);

  React.useEffect(() => {
    const img = new Image();
    img.src = src as string;
    img.addEventListener('load', onLoad);
    img.addEventListener('error', onError);
    return () => {
      img.removeEventListener('load', onLoad);
      img.removeEventListener('error', onError);
    };
  }, [src, onLoad, onError]);

  return (
    <>
      {link ? (
        <Link href={link} target="_blank">
          <ImageCarousel>
            <NextImage fill alt={alt} src={imgSrc} />
          </ImageCarousel>
        </Link>
      ) : (
        <ImageCarousel>
          <NextImage fill alt={alt} src={imgSrc} />
        </ImageCarousel>
      )}
    </>
  );
}

export default function Carousel(props: Props) {
  const { className, css } = props;
  const ref = React.useRef<AliceCarousel>(null);

  const items = React.useMemo(
    () =>
      (props.imageList || []).map((item) => (
        <AspectRatio key={item.alt} ratio={16 / 5}>
          <LazyLoader
            alt={item.alt}
            src={item.src}
            link={item.link}
            placeholderImg="/assets/landscape-loading.gif"
          />
        </AspectRatio>
      )),
    [props.imageList],
  );

  if (props.isLoading) {
    return (
      <CarouselContainer>
        <div
          style={{
            borderRadius: 99,
            height: 20,
            width: 20,
            backgroundColor: color.neutral2,
            marginRight: 4,
          }}
        />
        <AspectRatio ratio={16 / 5}>
          <div
            style={{
              height: '100%',
              width: '100%',
              backgroundColor: color.neutral2,
              borderRadius: 8,
            }}
          />
        </AspectRatio>
        <div
          style={{
            marginLeft: 4,
            borderRadius: 99,
            height: 20,
            width: 20,
            backgroundColor: color.neutral2,
          }}
        />
      </CarouselContainer>
    );
  }

  return (
    <CarouselContainer css={css} className={className}>
      <IconBox
        onClick={(e) => ref?.current?.slidePrev(e)}
        css={{
          display: 'none',
          '@md': {
            display: 'flex',
          },
        }}
      >
        <ChevronLeft />
      </IconBox>
      <div style={{ width: '100%' }}>
        <AliceCarousel
          mouseTracking
          responsive={{
            0: { items: 1 },
          }}
          autoPlay
          autoPlayInterval={5000}
          infinite
          items={items}
          disableButtonsControls
          ref={ref}
        />
      </div>
      <IconBox
        onClick={(e) => ref?.current?.slideNext(e)}
        css={{
          display: 'none',
          '@md': {
            display: 'flex',
          },
        }}
      >
        <ChevronRight />
      </IconBox>
    </CarouselContainer>
  );
}
