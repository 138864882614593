import { styled } from 'styles';

export const CarouselContainer = styled('div', {
  display: 'flex',
  width: '100%',
  px: 16,
  alignItems: 'center',
  mx: 'auto',
  '@sm': {
    maxWidth: 576,
  },
  '@md': {
    maxWidth: 768,
  },
  '@lg': {
    maxWidth: 992,
  },
  '@xl': {
    maxWidth: 1200,
  },
  '.alice-carousel__stage-item': {
    width: '100% !important',
  },
  '.alice-carousel__dots': {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  '.alice-carousel__dots-item:hover': {
    backgroundColor: '$primary3',
  },
  '.alice-carousel__dots-item.__active': {
    backgroundColor: '$primary',
  },
});

export const ImageCarousel = styled('div', {
  objectFit: 'cover',
  height: '100%',
  width: '100%',
  borderRadius: 8,
  overflow: 'hidden',
  position: 'relative',
});
