import Text from 'components/commons/text';
import { styled } from 'styles';
import typography from 'styles/typography';

export const ModalContainer = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$white',
  borderRadius: '5px',
  minHeight: '200px',
  padding: '1rem',
});

export const TitleModal = styled(Text, {
  ...typography.headline4,
  '@md': {
    ...typography.headline3,
  },
  textAlign: 'center',
});
