import Separator from 'components/commons/separator';
import Stack from 'components/commons/stack';
import Text from 'components/commons/text';
import Button from 'components/elements/button';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { CreditCard, XCircle } from 'react-feather';
import colors from 'styles/color';

import { bottomRight, positionRelative } from '../style';

interface EditBankAccountPromptModalProps {
  onContinue: () => void;
}
function NoBankAccountIcon() {
  const BIG_ICON_SIZE = 48;
  const SMALL_ICON_SIZE = 20;
  return (
    <div className={positionRelative()}>
      <CreditCard size={BIG_ICON_SIZE} color={colors.typeLightGrey} />
      <XCircle
        className={bottomRight()}
        size={SMALL_ICON_SIZE}
        color={colors.error}
      />
    </div>
  );
}
export default function EditBankAccountPromptModal(
  props: EditBankAccountPromptModalProps,
) {
  const { t } = useTranslation();
  return (
    <Stack alignItems="center" style={{ width: '100%' }} gap="sm">
      <NoBankAccountIcon />
      <Separator gap={8} />
      <Text variant="headline5">
        {t('common:required_extra', { extra: t('common:bank_account') })}
      </Text>
      <Text variant="body3" align="center">
        {t('common:please_fill_your_bank_account_info')}
      </Text>
      <Button type="button" onClick={props.onContinue}>
        {t('common:fill_data')}
      </Button>
    </Stack>
  );
}
