import { styled } from 'styles';

import { styles as _styles } from '../style';

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  ..._styles.breakPointsCss,
  '@lg': {
    maxWidth: 992,
    flexDirection: 'row',
  },
});

export const Image = styled('img', {
  objectFit: 'cover',
  height: '100%',
  width: '100%',
  objectPosition: '50% 50%',
  borderRadius: 8,
});

export const ImageContainer = styled('div', {
  width: '100%',
  mb: 24,
  '@md': {
    mb: 0,
    mr: 24,
  },
});

export const ChooseUsItemCard = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 24,
  backgroundColor: '$white',
  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
  borderRadius: 8,
  height: '100%',
});

export const styles = {
  cardTitle: {
    mt: 24,
    mb: 8,
    fontWeight: '700 !important',
  },
  chooseUsItemCard: {
    width: '100%',
    '@md': {
      width: '45%',
    },
  },
};
