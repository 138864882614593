import { Home } from 'modules/home';
import * as React from 'react';

export default function HomePage() {
  return <Home />;
}

// export function getStaticProps() {
//   return {
//     redirect: {
//       destination: '/maintenance',
//     },
//   };
// }
