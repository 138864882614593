import Text from 'components/commons/text';
import { css, styled } from 'styles';
import colors from 'styles/color';
import typography from 'styles/typography';

export const Section = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
});

export const SectionContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  py: 24,
  px: 16,
  '@sm': {
    maxWidth: 576,
  },
  '@md': {
    maxWidth: 768,
    py: 40,
  },
  '@lg': {
    maxWidth: 992,
  },
  '@xl': {
    maxWidth: 1200,
  },
});

export const TitleCardText = styled(Text, {
  ...typography.headline6,
  '@md': {
    ...typography.headline5,
  },
});

export const CardContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  boxShadow: '$shadow1',
  borderRadius: 8,
  padding: 16,
  '@md': {
    padding: 32,
  },
});

export const ProfileMenuActionBox = styled('div', {
  boxShadow: '0px 0px 4px 0px #00000026',
  padding: '16px',
  borderRadius: '8px',
  cursor: 'pointer',
});

export const textPrimary = css({
  color: colors.primary,
});
export const textGray = css({
  color: colors.typeGray,
});
export const positionRelative = css({
  position: 'relative',
});
export const bottomRight = css({
  position: 'absolute',
  right: '0%',
  bottom: '0%',
});
export const w100 = css({
  width: '100%',
});
