import { UserModel } from 'api-hooks/auth/model';
import formSetErrors from 'common/helpers/form-setError';
import { ApiResult } from 'common/repositories/common.model';
import Separator from 'components/commons/separator';
import Stack from 'components/commons/stack';
import Text from 'components/commons/text';
import Input from 'components/elements/field';
import Form from 'components/elements/form';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import useTranslation from 'next-translate/useTranslation';
import { useCallback, useMemo } from 'react';
import { Info } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import colors from 'styles/color';
import * as Yup from 'yup';

import { textGray } from '../style';

export interface EditBankAccountFormType {
  bankAccountName: string;
  bankName: string;
  bankAccountNumber: string;
}
interface EditBankAccountFormProps {
  onSubmit: (payload: EditBankAccountFormType) => Promise<ApiResult<unknown>>;
  user: UserModel;
}

export default function EditBankAccountForm(props: EditBankAccountFormProps) {
  const { t } = useTranslation();
  const schema = useMemo(
    () =>
      Yup.object().shape({
        bankAccountName: Yup.string().required(),
        bankName: Yup.string().required(),
        bankAccountNumber: Yup.string().required(),
      }),
    [],
  );
  const resolver = useYupValidationResolver(schema);
  const form = useForm<EditBankAccountFormType>({
    resolver,
    defaultValues: {
      bankAccountName: props.user.name || '',
      bankName: props.user.bankName || '',
      bankAccountNumber: props.user.bankAccountNumber || '',
    },
  });
  const toast = useToasts();

  const onSubmit = useCallback(
    async (payload: EditBankAccountFormType) => {
      try {
        const res = await props.onSubmit(payload);
        toast.addToast(res.message, {
          appearance: 'success',
          autoDismiss: true,
        });
      } catch (e) {
        if (e.errors) {
          formSetErrors(e.errors, form.setError);
        } else if (e.message) {
          toast.addToast(e.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      }
    },
    [form.setError, props, toast],
  );
  return (
    <Form methods={form} onSubmit={onSubmit} css={{ mt: 8 }} autoComplete="off">
      <Stack gap="md" justify="center">
        <Stack.Item>
          <Input
            type="text"
            name="bankAccountName"
            label={t('common:bank_account_owner_name')}
            disabled
            required
          />
          <Separator gap={8} />
          <Stack direction="horizontal" gap="xs">
            <Info size={24} color={colors.primary} />
            <Text variant="body3" className={textGray()}>
              {t('common:bank_account_owner_name_must_match_user_name')}
            </Text>
          </Stack>
        </Stack.Item>
        <Input
          name="bankName"
          type="text"
          label={t('common:bank_of_account')}
          placeholder={t('common:enter_your_extra', {
            extra: t('common:bank_of_account'),
          })}
          autoComplete="off"
          required
        />
        <Input
          name="bankAccountNumber"
          type="text"
          label={t('common:account_number')}
          placeholder={t('common:enter_your_extra', {
            extra: t('common:account_number'),
          })}
          autoComplete="off"
          required
        />
        <Stack.Item alignItems="center">
          <Input type="submit" css={{ maxWidth: 200, width: '100%' }} />
        </Stack.Item>
      </Stack>
    </Form>
  );
}
