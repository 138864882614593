import { useGetMe } from 'api-hooks/auth/query';
import { BannerModel, useGetBanners } from 'api-hooks/banner';
import NavigationEnum from 'common/navigation';
import { AspectRatio } from 'components/commons/aspect-ratio';
import DialogBackdrop from 'components/commons/dialog/dialog-backdrop';
import Stack from 'components/commons/stack';
import Carousel from 'components/widgets/carousel/carousel';
import useDialog from 'hooks/use-dialog';
import EditBankAccountModal from 'modules/profile/edit-bank-account-modal';
import { useRouter } from 'next/router';
import * as React from 'react';

import AboutUsSection from './about-us-section';
// import CallServiceSection from './call-service-section';
import ShippingTab from './order-tabs/shipping-tab';
// import OurCruiseLine from './our-cruise-line';
import OurFleetSection from './our-fleet';
import PolicyDialog from './policy-dialog';
import { Section, styles } from './style';

function carouselTransformer(banner: BannerModel) {
  return { alt: banner.title, src: banner.image.url, link: banner.link };
}

export default function Home() {
  const { data: dataBanner, isLoading: loadingBanner } = useGetBanners({
    params: { limit: -1 },
  });

  const dialog = useDialog();
  const { push } = useRouter();

  const imageList = React.useMemo(() => {
    if (dataBanner) {
      return (dataBanner.data || [])
        .filter((curr) => curr.image !== null)
        .map((item) => carouselTransformer(item));
    }
    return [];
  }, [dataBanner]);

  React.useEffect(() => {
    try {
      const expiredDialog =
        localStorage.getItem('expired-dialog') || new Date().toISOString();
      if (new Date(expiredDialog) <= new Date()) {
        dialog.showCustom({
          render(close) {
            return (
              <DialogBackdrop
                onClose={close}
                cancelable={false}
                css={{
                  '& > .react-reveal': {
                    height: '100%',
                  },
                }}
              >
                <PolicyDialog onClose={close} />
              </DialogBackdrop>
            );
          },
        });
      }
    } catch (e) {
    } finally {
    }
  }, [dialog]);

  const { data, isSuccess } = useGetMe();

  React.useEffect(() => {
    if (!data?.data) return;
    if (!data.data.bankAccountName || !data.data.bankAccountNumber) {
      dialog.showCustom({
        render(close) {
          return <EditBankAccountModal onClose={close} isPrompted />;
        },
      });
    }
  }, [dialog, data, isSuccess]);

  return (
    <>
      {loadingBanner ? (
        <Stack.Item alignItems="center">
          <Stack.Item css={{ ...styles.breakPointsCss, px: 16 }}>
            <AspectRatio ratio={16 / 5}>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: '#c7c7c7',
                  borderRadius: 8,
                }}
              />
            </AspectRatio>
          </Stack.Item>
        </Stack.Item>
      ) : (
        <>
          {dataBanner?.data && dataBanner.data.length > 0 && (
            <Carousel
              css={{ my: 16 }}
              imageList={imageList}
              isLoading={false}
            />
          )}
        </>
      )}
      <Section>
        <Stack
          rootCss={{
            ...styles.breakPointsCss,
          }}
        >
          <Stack.Item
            css={{
              my: 16,
              borderRadius: 12,
              boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
              padding: '40px 28px',
            }}
          >
            <ShippingTab
              onSubmit={async (values, queryParams) => {
                push({
                  pathname: NavigationEnum.searchShipping,
                  search: queryParams,
                });
              }}
              changeRoute={false}
            />
          </Stack.Item>
        </Stack>
      </Section>
      <AboutUsSection />
      <OurFleetSection />
      {/* <CallServiceSection /> */}
    </>
  );
}
